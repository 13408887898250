import React, { useEffect, useState, useRef } from "react";
import * as s from "../styles/globalStyles";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import styled from "styled-components";

export const StyledButton = styled.button`
  padding: 10px;
  border: 2px solid #e7e7e7;
  background-color: var(--black);
  padding: 10px;
  font-weight: bold;
  color: var(--off-white);
  width: 100px;
  cursor: pointer;
  :hover{
    background-color: var(--off-white); 
    color: var(--black);
  }
`;

export const StyledLink = styled.a`
  color: var(--black);
  text-decoration: none;
`;

export const MintAmountButton = styled.button`
  padding: 10px;
  border: 2px solid black;
  background-color: var(--off-white);
  padding: 10px;
  font-weight: bold;
  color: var(--black);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover{
    background-color: var(--red); 
    color: var(--off-white);
  }
`;

export const StyledSquareButton = styled.button`
  padding: 10px;
  border: 2px solid black;
  background-color: var(--off-white);
  padding: 10px;
  font-weight: bold;
  color: var(--black);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover{
    background-color: var(--red); 
    color: var(--off-white);
  }
`;

const MintWindow = ({
  onSubmitHandler,
  searchTerm,
  onInputChange,
  error,
}) => {
    const data = useSelector((state) => state.data);
    const blockchain = useSelector((state) => state.blockchain);
    const dispatch = useDispatch();
    const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
          dispatch(fetchData(blockchain.account));
        }
      };
    const [claimingNft, setClaimingNft] = useState(false);
    const [feedback, setFeedback] = useState('');
    const [mintSuccessful, setMintSuccessful] = useState(false);
    const [mintTxHash, setMintTxHash] = useState(null);
    const [mintAmount, setMintAmount] = useState(1);

    const ShowMintButtons = () => {
        if (data.paused == null) return null;
        if (data.paused) return (<s.SpacerXSmall />);
        if (!data.paused) return (<>
          <s.SpacerSmall />
          <s.Container ai={"center"} jc={"start"} fd={"row"}>
            <MintAmountButton
              disabled={claimingNft ? 1 : 0}
              onClick={(e) => {
                e.preventDefault();
                decrementMintAmount();
              }}
            >
              -
            </MintAmountButton>
            <s.SpacerXSmall />
            <StyledSquareButton
              disabled={claimingNft ? 1 : 0}
              onClick={(e) => {
                e.preventDefault();
                mintNFTsPublic();
                getData();
              }}
              style={{ width: "fit-content"}}
            >
              {claimingNft ? "MINTING" : getMintButtonText()}
            </StyledSquareButton>
            <s.SpacerXSmall />
            <MintAmountButton
              disabled={claimingNft ? 1 : 0}
              onClick={(e) => {
                e.preventDefault();
                incrementMintAmount();
              }}
            >
              +
            </MintAmountButton>
          </s.Container>
        </>);
      };
    
      const getMintButtonText = () => {
        return "MINT " + mintAmount
      }

      const mintNFTsPublic = () => {
        let cost = CONFIG.WEI_COST;
        let gasLimit = CONFIG.GAS_LIMIT;
    
        let totalCostWei = String(cost * mintAmount);
        let totalGasLimit = gasLimit;
        if (parseInt(mintAmount) > 1 ){
          totalGasLimit = gasLimit + (mintAmount * 3000);
        }
    
        console.log("Cost: ", totalCostWei);
        console.log("Gas limit: ", String(totalGasLimit));
        setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
        setClaimingNft(true);
        setMintSuccessful(false);
        blockchain.smartContract.methods
          .mint(mintAmount)
          .send({
            gasLimit: String(totalGasLimit),
            to: CONFIG.CONTRACT_ADDRESS,
            from: blockchain.account,
            value: totalCostWei
          })
          .once("error", (err) => {
            console.log(err);
            setFeedback("Sorry, something went wrong. Please try again later.");
            setClaimingNft(false);
          })
          .then((receipt) => {
            console.log(receipt);
            setFeedback(null);
            setMintSuccessful(true);
            setMintTxHash(receipt.transactionHash);
            setClaimingNft(false);
            dispatch(fetchData(blockchain.account));
          });
      };
    
      const decrementMintAmount = () => {
        let newMintAmount = mintAmount - 1;
        if (newMintAmount < 1) {
          newMintAmount = 1;
        }
        setMintAmount(newMintAmount);
      };
    
      const incrementMintAmount = () => {
        let newMintAmount = mintAmount + 1;
        if (newMintAmount > CONFIG.MAX_MINT_AMOUNT) {
          newMintAmount = CONFIG.MAX_MINT_AMOUNT;
        }
        setMintAmount(newMintAmount);
      };

      const ShowFeedback = () => {
        if (data.paused != null && data.paused) return (
          <s.TextDescription style={{ textAlign: "center", color: "var(--black)" }}>
            Sale not active - check back later!           
          </s.TextDescription>
        );
        if (data.paused == null || !data.paused) return (
          <s.TextDescription style={{ textAlign: "center", color: "var(--black)" }}>
            {feedback}         
          </s.TextDescription>
        );
      }

      const getConfig = async () => {
        const configResponse = await fetch("/config/config.json", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        const config = await configResponse.json();
        SET_CONFIG(config);
      };
    
      useEffect(() => {
        getConfig();
      }, []);
    
      useEffect(() => {
        getData();
      }, [blockchain.account]);


    const [CONFIG, SET_CONFIG] = useState({
        CONTRACT_ADDRESS: "",
        SCAN_LINK: "",
        NETWORK: {
          NAME: "",
          SYMBOL: "",
          ID: 0,
        },
        NFT_NAME: "",
        SYMBOL: "",
        MAX_SUPPLY: 1,
        WEI_COST: 0,
        DISPLAY_COST: 0,
        GAS_LIMIT: 0,
        MARKETPLACE: "",
        MARKETPLACE_LINK: "",
        SHOW_BACKGROUND: false,
      });

      React.useEffect(() => {
        getData();
      }, []);

  return (

    <s.Container
        flex={9}
        ai={"start"}
        jc={"center"}
        style={{ 
            backgroundColor: "var(--red)", 
            paddingBottom: "24px", 
            paddingRight: "24px", 
            paddingLeft: "24px", 
            paddingTop: "0px",
            flexDirection: "column"
        }}
    >      

        {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
        <>
            <s.TextTitle
            style={{ textAlign: "center", fontSize: 22, color: "var(--off-white)" }}
            >
            SOLD OUT! Find us on {CONFIG.MARKETPLACE}.
            </s.TextTitle>
        </>
        ) : (
        <s.TextTitle
            style={{
            textAlign: "center",
            fontSize: 50,
            fontWeight: "bold",
            color: "var(--off-white)",
            }}
        >
            {data.totalSupply} / {CONFIG.MAX_SUPPLY}
        </s.TextTitle>
        )}

        {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
        <>
        </>
        ) : (
        <>
            <s.TextTitle style={{ textAlign: "start", color: "var(--off-white)" }} >
                {CONFIG.DISPLAY_COST}{" "} {CONFIG.NETWORK.SYMBOL} excl gas.
            </s.TextTitle>
            <s.TextTitle style={{ textAlign: "start", color: "var(--off-white)" }} >
                Max 5 per tx, 20 per wallet
            </s.TextTitle>
            <s.SpacerXSmall />

            {blockchain.account === "" || blockchain.smartContract === null ? (
            <s.Container ai={"start"} jc={"start"}>
                <s.SpacerSmall />
                <StyledButton onClick={(e) => {
                    e.preventDefault();
                    dispatch(connect());
                    getData();
                }}>
                    CONNECT
                </StyledButton>
                {blockchain.errorMsg !== "" ? (
                <>
                    <s.SpacerSmall />
                    <s.TextDescription style={{textAlign: "center", color: "var(--off-white)" }}>
                        {blockchain.errorMsg}
                    </s.TextDescription>
                </>
                ) : null}
            </s.Container>
            ) : (
            <>
                <ShowFeedback />
                {mintSuccessful ? 
                <StyledLink target={"_blank"} href={CONFIG.TX_LINK + mintTxHash}>
                    Mint successful! Click to view transaction
                </StyledLink> : null}

                {/* {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (): () */}
                <ShowMintButtons />
            </>
            )}
        </>
        )}
  </s.Container>
  );
};

export default MintWindow;
