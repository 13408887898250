import React, { useEffect, useState, useRef } from "react";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import Marquee from "react-fast-marquee";
import useCollapse from 'react-collapsed';
import MintWindow from './components/MintWindow';

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: center;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const ResponsiveHeader = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 0;
  padding-bottom: 24px;
  @media (min-width: 767px) {
    flex-direction: row;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const ResponsiveFooter = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 0;
  padding-bottom: 24px;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const ResponsiveHeaderMobile = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media (min-width: 768px) {
    display: none;
  }
`;

export const StyledLogo = styled.img`
  width: 300px;
  transition: width 0.5s;
  transition: height 0.5s;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  background-color: var(--accent);
  border-radius: 100%;
  width: 300px;
  @media (min-width: 900px) {
    width: 350px;
  }
  @media (min-width: 1000px) {
    width: 400px;
  }
  transition: width 0.5s;
`;

export const TeamImg = styled.img`
  background-color: var(--accent);
  border: 2px solid var(--black);
  width: 250px;
  transition: width 0.5s;
`;

export const MarqueeImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  background-color: var(--accent);
  border: 5px solid #FFFFFF;
  width: 300px;
  transition: width 0.5s;
  margin-right: 30px;
  @media (max-width: 768px) {
    width: 150px;
    border: 2px solid #FFFFFF;
  };
`;

function CollapsibleMintFrame() {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  return (
    <div className="collapsible" style={{ backgroundColor:"var(--red)", borderRadius: "5px", border:"1px solid var(--black)", overflow:"hidden" }}>
        <div className="header" {...getToggleProps()}>
            {isExpanded ? '-' : "Click here to mint."}
        </div>
        <div {...getCollapseProps()}>
          <MintWindow/>
        </div>
    </div>
    );
}

export const TeamCard = function TeamCard(props) {
  const [name] = useState(props.name);
  const [role] = useState(props.role);
  const [image] = useState(props.image);


  return (
    <s.Container
        flex={2}
        jc={"center"}
        ai={"center"}
        style={{ width: "fit-content", padding: 12, backgroundColor: "var(--red)", border: "2px solid var(--black)", boxShadow: "2px 2px 2px var(--black)"}}
      >

      <TeamImg alt={"example"} src={`/config/images/${image}`}/>
      <s.TextDescription style={{ fontSize: 26, textAlign: "center", color: "var(--white)" }}>
        {name}
      </s.TextDescription>
      <s.TextDescription style={{ fontSize: 14, textAlign: "center", color: "var(--white)" }}>
        {role}
      </s.TextDescription>
    </s.Container>

  );
}

export const SocialButton = function SocialButton(props) {
  const [href] = useState(props.href);
  const [image] = useState(props.image);
  const [alt] = useState(props.alt);
  const [colour] = useState(props.colour);

  return (
    <a href={href}>
      <img src={`/config/images/${image}`} alt={alt} height="30px" style={{ padding: "5px", border: `2px solid ${colour}`}}></img>
    </a>
  );
}

function App() {
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  const LinksMenu = () => {
    return (
      <s.Container flex={1} ai={"center"} jc={"end"}
        style={{ 
          backgroundColor: "var(--off-white)", 
          width: "90%", 
          flexDirection: "row"
        }}
      >          
        <SocialButton alt="Discord" href={CONFIG.DISCORD_LINK} image="discord-black.png" colour="black" />
        <s.SpacerXSmall />
        <SocialButton alt="Twitter" href={CONFIG.TWITTER_LINK} image="twitter-black.png" colour="black" />
        <s.SpacerXSmall />
        <SocialButton alt="Opensea" href={CONFIG.MARKETPLACE_LINK} image="opensea-black.png" colour="black" />
        <s.SpacerXSmall />
        <SocialButton alt="Etherscan" href={CONFIG.SCAN_LINK} image="etherscan-black.png" colour="black" />
      </s.Container>
    );
  };

  return (
    <s.Screen>
      <s.Container
          flex={1}
          ai={"center"}
          style={{ minHeight:"10vh", maxHeight:"10vh", backgroundColor: "var(--off-white)" }}
        >
          <LinksMenu/>
      </s.Container>

      <s.LandingZoneContainer
          style={{ backgroundColor: "var(--off-white)" }}
          image={"/config/images/background.png"}
        >
        <CollapsibleMintFrame/>
      </s.LandingZoneContainer>

      <s.SpacerHorizontalXLarge />

      <s.ScreenSmall>
        <s.Container
          flex={1}
          ai={"center"}
          style={{ paddingTop: 24, paddingBottom: 24, backgroundColor: "var(--black)" }}
        >

        <Marquee gradient={false} >
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m1/0.png"}/>
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m1/1.png" }/>
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m1/2.png"}/>
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m1/3.png"}/>
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m1/3a.png"}/>
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m1/4.png"}/>
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m1/5.png"}/>
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m1/6.png"}/>
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m1/6a.png"}/>
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m1/7.png"}/>
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m1/8.png"}/>
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m1/9.png"}/>
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m1/9a.png"}/>
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m1/10.png"}/>
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m1/11.png"}/>
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m1/12.png"}/>
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m1/12a.png"}/>
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m1/13.png"}/>
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m1/14.png"}/>
        </Marquee>

        <s.SpacerMedium />


        <Marquee direction="right" gradient={false}>
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m2/1.png" } />
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m2/2.png"}/>
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m2/3.png"}/>
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m2/3a.png"}/>
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m2/4.png"}/>
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m2/5.png"}/>
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m2/6.png"}/>
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m2/6a.png"}/>
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m2/7.png"}/>
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m2/8.png"}/>
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m2/9.png"}/>
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m2/9a.png"}/>
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m2/10.png"}/>
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m2/11.png"}/>
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m2/12.png"}/>
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m2/12a.png"}/>
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m2/13.png"}/>
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m2/14.png"}/>
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m2/15.png"}/>
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m2/15a.png"}/>
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m2/16.png"}/>
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m2/17.png"}/>
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m2/18.png"}/>
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m2/18a.png"}/>
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m2/19.png"}/>
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m2/20.png"}/>
          <MarqueeImg alt={"AgedZuki"} src={"/config/images/marquee/m2/21.png"}/>
        </Marquee>

        </s.Container>
      </s.ScreenSmall>

      <s.ScreenSmall style={{ backgroundColor: "var(--off-white)"}}>
        <s.AboutSectionContainer flex={1} ai={"center"} >
            <s.TextSubTitle> ABOUT AGEDZUKI </s.TextSubTitle>
            <s.SpacerMedium /><s.HorizontalRule /><s.SpacerMedium />
            <s.TextParagraph style={{ color: "var(--red)" }}>
              After losing the great rebellion against the mad scientist, Bobu the Beanfarmer, the children of The Garden were stolen away and transformed into red beans. <br/>
              Bobu's end goal is unknown, but not wanting to find out, the parents of the lost ones take up their golf clubs, rolled newspapers and feather dusters for one last attempt at saving their children.<br/>
              AGEDZUKI is a collection of {CONFIG.MAX_SUPPLY} NFTs, created as an unofficial, non-canonical homage to the original AZUKI collection.
            </s.TextParagraph>
        </s.AboutSectionContainer>
      </s.ScreenSmall>

      <s.ScreenSmall>
        <s.Container flex={1} ai={"center"} jc={"center"}
          style={{ padding: 24, backgroundColor: "var(--off-white)" }}
        >
          <s.TextSubTitle> AGED CARE PROVIDERS </s.TextSubTitle>
          <s.SpacerMedium /> <s.HorizontalRule/> <s.SpacerMedium />
          <s.TeamSection flex={1} 
            style={{ backgroundColor: "var(--off-white)" }} Team>
            <TeamCard name="EVIO" role="Community Manager" image="evio.png" />
            <s.SpacerLarge />
            <TeamCard name="GYRO" role="Community Manager" image="gyro.png" />
            <s.SpacerLarge />
            <TeamCard name="BADAIM" role="Artist" image="badaim.png" />
            <s.SpacerLarge />
            <TeamCard name="FLGRNT" role="Developer" image="flgrnt.png" />
          </s.TeamSection>
          <s.SpacerLarge/>
        </s.Container>
      </s.ScreenSmall>

      <s.ScreenSmall>
        <s.Container
          flex={1}
          ai={"center"}
          jc={"center"}
          style={{ padding: 12, backgroundColor: "var(--black)" }}
        >
            <s.Container flex={1} ai={"center"} jc={"end"}
              style={{ 
                backgroundColor: "var(--black)", 
                flexDirection: "row",
                width:"90%"
              }}
            >   

            <s.Container flex={1} ai={"start"} jc={"center"} style={{maxWidth:"50%"}}>   
              <s.TextTitle
                style={{
                  textAlign: "start",
                  fontSize: 24,
                  fontWeight: "bold",
                  color: "var(--white)"
                }}
              >
                AGEDZUKI
              </s.TextTitle>
            </s.Container>


            <s.Container flex={1} ai={"center"} jc={"end"}
              style={{ 
                backgroundColor: "var(--black)", 
                flexDirection: "row",
                maxWidth:"50%"
              }}
            >          
              <SocialButton alt="Discord" href={CONFIG.DISCORD_LINK} image="discord.png" colour="white" />
              <s.SpacerXSmall />
              <SocialButton alt="Twitter" href={CONFIG.TWITTER_LINK} image="twitter.png" colour="white" />
              <s.SpacerXSmall />
              <SocialButton alt="Opensea" href={CONFIG.MARKETPLACE_LINK} image="opensea.png" colour="white" />
              <s.SpacerXSmall />
              <SocialButton alt="Etherscan" href={CONFIG.SCAN_LINK} image="etherscan.png" colour="white" />
            </s.Container>
          </s.Container>
        </s.Container>
      </s.ScreenSmall>
    </s.Screen>
  );
}

export default App;
