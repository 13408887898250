import styled from "styled-components";

// Used for wrapping a page component
export const Screen = styled.div`
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

// Used for wrapping a page component
export const ScreenSmall = styled.div`
  background-color: var(--primary);
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  display: flex;
  nin-height: 30vh;
  flex-direction: column;
`;

// Used for wrapping a page component
export const Footer = styled.div`
  background-color: var(--primary);
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

// Used for providing space between components
export const HorizontalRule = styled.hr`
  color: var(--red);
  width: 20%;
  @media (max-width: 768px) {
    width: 70%;
  }
`;

// Used for providing space between components
export const SpacerXSmall = styled.div`
  height: 8px;
  width: 8px;
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
  height: 24px;
  width: 24px;
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
`;

// Used for providing space between components
export const SpacerHorizontal = styled.div`
  width: 16px;
`;

// Used for providing space between components
export const SpacerHorizontalLarge = styled.div`
  width: 128px;
`;

// Used for providing space between components
export const SpacerHorizontalXLarge = styled.div`
  width: 128;
  @media (max-width: 768px) {
    display: none;
  }
`;

// Used for providing space between components
export const SpacerHorizontalXXLarge = styled.div`
  width: 256;
  @media (max-width: 768px) {
    display: none;
  }
`;

// Used for providing a wrapper around a component
export const LandingZoneContainer = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  background-color: ${({ test }) => (test ? "black" : "none")};
  min-height: 90vh;
  max-height: 90vh;
  width: 100%;
  @media (max-width: 768px) {
    background-image: ${`url(/config/images/background_mobile.png)`};
    justify-content: ${({ jc }) => (jc ? jc : "flex-end")};
    align-items: "${({ ai }) => (ai ? ai : "center")}";
    padding-bottom: 70px;
    padding-left: 24px;
    padding-right: 24px;
  };
  @media (min-width: 769px) {
    background-image: ${`url(/config/images/background_light.png)`};
    justify-content: ${({ jc }) => (jc ? jc : "end")};
    align-items: ${({ ai }) => (ai ? ai : "end")};
    padding: 24px;
  };
  background-size: cover;
  background-position: center;
`;

// Used for providing a wrapper around a component
export const AboutSectionContainer = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 250px;
  padding-right: 250px;
  @media (max-width: 768px) {
    padding-left: 12px;
    padding-right: 12px;
  };
`;

// Used for providing a wrapper around a component
export const Container = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
`;

export const TextTitle = styled.p`
  color: var(--primary-text);
  font-size: 22px;
  font-weight: 500;
  line-height: 1.6;
  @media (max-width: 768px) {
    font-size: 16px;
  };
`;

export const TextSubTitle = styled.p`
  font-size: 40px;
  font-weight: bold;
  color: var(--red);
  text-align: center;
`;

export const TextDescription = styled.p`
  color: var(--primary-text);
  font-size: 16px;
  line-height: 1.6;
`;

export const TextParagraph = styled.p`
  color: var(--black);
  font-size: 20px;
  line-height: 1.6;
  text-align: center;
`;

export const TextFooter = styled.p`
  color: var(--accent-text);
  font-size: 16px;
  line-height: 1.6;
`;

export const StyledClickable = styled.div`
  :active {
    opacity: 0.6;
  }
`;

export const TeamSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: stretched;
  align-items: center;
  @media (max-width: 767px) {
      flex-direction: column;
  }
`;

// @media (max-width: 767px) {
//   flex-direction: column;
// }
// @media (max-width: 1800px) {
//   width: 70%;
//   background-color: var(--black);
// }
// @media (min-width: 1800px) {
//   width: 50%;
// }
